app.factory('TicketFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};
	
    _factory.getCourseMax = function(authorId) {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            $http.get("api/ticketCourseMax/list/?memberId=" + UserFactory.user._id).then(function(data) {
                defer.resolve(data.data);
            });
        });
        return defer.promise;
    };

    _factory.applyMoreCoure = function(params) {
        return $http.post("api/ticketCourseMax/create", params);
    };

    _factory.applyPublicCourse = function(params) {
        return $http.post("api/ticketCoursePublic/create", params);
    };

    _factory.cancelApplyPublicCourse = function(params) {
        return $http.post("/api/ticketCoursePublic/cancel", params);
    };
    
    _factory.applyPublicProject = function (params) {
		return $http.post("api/ticketProjectPublic/create", params);
	};

    // _factory.getInvitedProjects = function() {
    //     var defer = $q.defer();
    //     UserFactory.getUser().then(function() {
    //         $http.get("api/invite/list-invited-projects?email=" + UserFactory.user.email).then(function(data) {
    //             _factory.projects = data.data;
    //             defer.resolve(_factory.projects);
    //         });
    //     });
    //     return defer.promise;
    // };

    // _factory.create = function(params) {
    //     return $http.post("api/course/create", params);
    // };

    // _factory.update = function(params) {
    //     return $http.post("api/course/update", params);
    // };

	
    // _factory.setPublic = function(params) {
    //     return $http.post("api/project/set-public", params);
    // };

    // _factory.moveToTrash = function(params) {
    //     return $http.post("api/course/move-to-trash", params);
    // };

    // _factory.restore = function(params) {
    //     return $http.post("api/course/restore", params);
    // };

    // _factory.remove = function(params) {
    //     return $http.post("api/course/remove", params);
    // };

    // _factory.emptyTrash = function() {
    //     return $http.post("api/course/empty-trash");
    // }

    // // _factory.clone = function(project, newName, newDescription, folderId) {
    // //     return $http.post("api/project/clone", {
    // //         id: project._id,
    // //         name: newName || project.name,
    // //         description: newDescription || project.description,
    // //         folderId: folderId
    // //     });
    // // };

    // _factory.uploadThumbnail = function(file) {
    //     return Upload.upload({
    //         url: 'api/course/upload-thumbnail',
    //         data: { file: file },
    //     });
    // };

    return _factory;
});
