app
.factory('CategoryFactory', function($q, $http) {

    var _factory = {};
    _factory.categories = [];

    var defer = $q.defer();

    $http.get("/api/category/list").then(function (data) {
        _factory.categories = data.data;
        defer.resolve(_factory.categories);
    });

    _factory.getCategories = function () {
        return defer.promise;
    };

    return _factory;
});
