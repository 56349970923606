app.factory('MyProjectFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};

    _factory.getTrash = function () {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            $http.get("/api/project/list/?start=0&len=500&isDeleted=true&authorId=" + UserFactory.user._id).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
        });
        return defer.promise;
    };

    _factory.getProjects = function(isPublic) {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            var url = "/api/project/list/?start=0&len=500&isDeleted=false&authorId=" + UserFactory.user._id;
            if (isPublic) {
                url += "&isPublic";
            }
            $http.get(url).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
        });
        return defer.promise;
    };

    _factory.getInvitedProjects = function() {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            $http.get("/api/invite/list-invited-projects?email=" + UserFactory.user.email).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
        });
        return defer.promise;
    };

    _factory.create = function(params) {
        return $http.post("/api/project/create", params);
    };

    _factory.update = function(params) {
        return $http.post("/api/project/update", params);
    };

    _factory.updateThumbnail = function(params) {
        return $http.post("/api/project/update-thumbnail", params);
    };

    _factory.setPublic = function(params) {
        return $http.post("/api/project/set-public", params);
    };

    _factory.updateTheme = function(params) {
        return $http.post("/api/project/update-theme", params);
    };

    _factory.moveToTrash = function(params) {
        return $http.post("/api/project/move-to-trash", params);
    };

    _factory.restore = function(params) {
        return $http.post("/api/project/restore", params);
    };

    _factory.remove = function(params) {
        return $http.post("/api/project/remove", params);
    };

    _factory.emptyTrash = function () {
        return $http.post("/api/project/empty-trash");
    };

    _factory.clone = function(project, newName, newDescription, folderId) {
        return $http.post("/api/project/clone", {
            id: project._id,
            name: newName || project.name,
            description: newDescription || project.description,
            folderId: folderId
        });
    };

    _factory.uploadThumbnail = function(file) {
        return Upload.upload({
            url: '/api/project/upload-thumbnail',
            data: { file: file },
        });
    };
    
    _factory.uploadAttachment = function (id, file) {
		return Upload.upload({
			url: '/api/project/' + id + '/upload-attachment',
			data: {
				file: file
			},
		});
	};

    return _factory;
});
