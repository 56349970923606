app
    .factory('CellFactory', ['$http', '$q', function($http, $q) {
        // $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        var _factory = {};

        // _factory.getPages = function (projectId) {
        // return $http.get("api/project/list-pages/?id=" + projectId);
        // };
        _factory.create = function(pageId) {
            return $http.post("api/cell/create", {
                pageId: pageId,
                type: "empty"
            });
        };


        _factory.update = function(cell) {
            return $http.post("api/cell/update", cell);
        };

        _factory.updateCells = function(cells) {
            return $http.post("api/cell/update-cells", cells);
        };

        _factory.uploadImageFromUrl = function(url) {
            return $http.post("api/cell/upload-image-from-url", { url: url });
        };

        _factory.info = function(id) {
            return $http.get("api/cell/info/" + id);
        };
        _factory.getUrlOpenGraph = function(url) {
            return $http.get("api/cell/get-url-open-graph/?url=" + url);
        };




        _factory.textSegment = function(string) {
            console.log(string);

            // $http.defaults.headers.post["Content-Type"] = "text/plain";
            // return $http({
            //     method: 'POST',
            //     url: 'https://dyn.jibaoviewer.com/TextSegment',
            //       // data: { word: JSON.stringify(string) },
            //       data: "word="+string  ,
            //      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            // });


            var deferred = $q.defer();
            var datas = [];
            $.ajax({
                async: false,
                type: 'POST',
                url: 'https://dyn.jibaoviewer.com/TextSegment',
                data: {
                    word: string

                },
                success: function(data) {
                    console.log($.parseJSON(data));
                    datas = $.parseJSON(data);
                    deferred.resolve(datas);
                }
            });


            return deferred.promise;




            // return $http({
            //     method: 'POST',
            //     url: "https://dyn.jibaoviewer.com/TextSegment",
            //     data: { word: JSON.stringify(string) }
            // });


        };




        _factory.googleKnowledgeGraphSearch = function(terms) {
            var deferred = $q.defer();
            var service_url = 'https://kgsearch.googleapis.com/v1/entities:search';
            var similarTerms = [];
            console.log(terms);

            terms.forEach(function(term, index) {
                var params = {
                    'languages': 'zh',
                    'query': term,
                    'limit': 10,
                    'indent': true,
                    'key': 'AIzaSyBP3fse68A7dMOY-vZ9MF7iSHCngxn0vJ8',
                };

                $.getJSON(service_url + '?callback=?', params, function(response) {
                    var strings = '';
                    $.each(response.itemListElement, function(i, element) {
                        strings += JSON.stringify(element);
                        if (i == 9) {
                            var regex = /[{}:'"a-zA-Z0-9@=?。&;-_.,/()!%]+/g;
                            var strKeyWord = strings.replace(regex, "");
                            // console.log(strKeyWord);
                            similarTerms.push(strKeyWord);
                        }
                    });
                }).done(function() {
                    if (index == terms.length - 1) {
                        deferred.resolve(similarTerms);
                    }
                });;



            });

            return deferred.promise;


        }






        _factory.dynamicSearch = function(words) {
            console.log(JSON.stringify(words));
            // return $http.get("api/nodejieba/?string=" + string);
            // words = ["鋼鐵人", "音樂"];

            var deferred = $q.defer();
            var datas = [];
            $.ajax({
                async: false,
                type: 'POST',
                url: "https://dyn.jibaoviewer.com/DynamicSearch",
                data: {
                    words: JSON.stringify(words)
                },
                success: function(data) {
                    // console.log($.parseJSON(data));
                    datas = $.parseJSON(data);
                    deferred.resolve(datas);
                }
            });


            return deferred.promise;

            // return $http.post("https://dyn.jibaoviewer.com/DynamicSearch", {
            //     words: words
            // });

        };


        // _factory.getCustomer = function (id) {
        //     return $http.get(urlBase + '/' + id);
        // };

        // _factory.insertCustomer = function (cust) {
        //     return $http.post(urlBase, cust);
        // };

        // _factory.updateCustomer = function (cust) {
        //     return $http.put(urlBase + '/' + cust.ID, cust)
        // };

        // _factory.deleteCustomer = function (id) {
        //     return $http.delete(urlBase + '/' + id);
        // };

        // dataFactory.getOrders = function (id) {
        //     return $http.get(urlBase + '/' + id + '/orders');
        // };

        return _factory;
    }]);
