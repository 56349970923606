app
    .factory('PageFactory', ['$http', function($http) {

        var _factory = {};

        _factory.getPages = function(projectId, start, len) {
            if (start !== undefined) {
                return $http.get("/api/project/list-pages/?id=" + projectId + "&start=" + start + "&len=" + len);    
            }
            else {
                return $http.get("/api/project/list-pages/?id=" + projectId);
            }
        };

        _factory.info = function(id) {
            return $http.get("/api/page/info/" + id);
        };

        _factory.clearDeletedPages = function(projectId) {
            return $http.get("/api/project/clear-deleted-pages/?id=" + projectId);
        };

        _factory.create = function(projectId, page, index) {
            return $http.post("/api/page/create", {
                projectId: projectId,
                layout: page.layout || 1,
                cells: page.cells || [],
                index: index
            });
            // TDAPP.onEvent("Page-Create");
        };

        _factory.cloneNewPage = function(projectId, page, index) {
            return $http.post("/api/page/cloneNewPage", {
                id: page._id,
                projectId: projectId
            });
            // TDAPP.onEvent("Page-Create");
        };


        _factory.clone = function(pageId) {
            return $http.post("/api/page/clone", {
                id: pageId
            });
            // TDAPP.onEvent("Page-Create");
        };

        _factory.updatePagesOrder = function(projectId, pageIds) {
            if (projectId && pageIds) {
                return $http.post("/api/project/update-pages", {
                    id: projectId,
                    pageIds: pageIds
                });
            }
        };

        _factory.updatePages = function(pages) {
            var ps = [],
                cellIds = [];

            ps = pages.map(function(page) {

                if (page.cells) {
                    cellIds = page.cells.map(function(c) {
                        return c._id;
                    });
                }

                return {
                    _id: page._id,
                    layout: page.layout,
                    author: page.author,
                    isDeleted: page.isDeleted,
                    cells: cellIds
                };
            });

            return $http.post("/api/page/update-pages", ps);
        };

        return _factory;
    }]);
