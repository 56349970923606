/**
 * Created by Tinny Wang on 2017/9/14.
 */

app.factory('NavFactory', function ($rootScope, DataDisplayFactory) {
	var dataDF = DataDisplayFactory;
		
	var fnObj = getFnObj(),
		avatarObj = getAvatarObj('avatar'),
		thumbObj = getThumbObj('image.url'),
		barObj = getBarObj('bar'),
		sortObj = {
			field: "order",
			title: "排序",
			getValue: dataDF.displaySort
		},
		idxObj = getIdxObj('#'),
		rankObj = getIdxObj('排名');

	var _factory = {
		scope: null
	};

	_factory.setScope = function (scope) {
		_factory.scope = scope;
	};

	_factory.openEditingOperator = function () {
		dataDF.openEditingOperator();
	};

	_factory.getAuthLabel = getAuthLabel;
	
	function getAuthLabel(authCode) {
		let label = '不公開';
		switch (authCode) {
			case 0:
				label = '公開';
				break;
			case 1:
				label = '保護';
				break;
		}
		return label;
	}

	function operator($event, row) {
		var classes = $event.target.className.split(' ');
		if (!classes.includes('disabled')){
			dataDF.fnOperator($event, row);

			$rootScope.$broadcast("FN_OPERATOR", {
				row: row,
				callback: function (result) {
					console.log('>>> FN_OPERATOR');
				}
			});
		}
	}

	function getFnObj(fnTypes, condition) {
		fnTypes = fnTypes || ['edit', 'del'];
		return {
			field: "_id",
			title: "操作",
			getValue: function (scope, row) {
				return dataDF.displayFn(scope, row, fnTypes);
			},
			getClass: dataDF.addClass,
			clickEvent: operator
		};
	}

	function getAvatarObj(field) {
		return {
			field: field,
			getValue: dataDF.displayMember,
			getClass: function () {
				return 'd-avatar';
			}
		};
	}

	function getThumbObj(field) {
		return {
			field: field,
			getValue: dataDF.displayThumbnail,
			getClass: function () {
				return 'd-thumb';
			}
		};
	}

	function getIdxObj(field) {
		return {
			title: field,
			getClass: function () {
				return 'd-idx';
			},
			getValue: function (scope, row, rowIdx) {
				return (rowIdx + 1);
			}
		};
	}

	function getBarObj(field) {
		return {
			field: field,
			getValue: dataDF.displayBar,
			getClass: dataDF.addClass
		};
	}

	function getProjectObj(field, idField) {
		return {
			field: field,
			title: '專案名稱',
			getValue: function ($scope, row) {
				let url = '/org/' + row.publisher + '/project/' + dataDF.getSubFieldVal(idField, row);
				return dataDF.displayLink($scope, row, field, url);
			},
			getClass: function () {
				return 'd-link';
			}
		};
	}

	function getCourseObj(field, idField) {
		return {
			field: field,
			title: '課程名稱',
			getValue: function ($scope, row) {
				let url = '/org/' + row.publisher + '/course/' + dataDF.getSubFieldVal(idField, row);
				return dataDF.displayLink($scope, row, field, url);
			},
			getClass: function () {
				return 'd-link';
			}
		};
	}

	function getMemberObj(field, idField) {
		return {
			field: field,
			title: '名稱',
			getValue: function ($scope, row) {
				let url = '/user/' + dataDF.getSubFieldVal(idField, row);
				return dataDF.displayLink($scope, row, field, url);
			},
			getClass: function () {
				return 'd-link';
			}
		};
	}

	function getAdObj(field, idField) {
		return {
			field: field,
			title: '名稱',
			getValue: function ($scope, row) {
				let url = dataDF.getSubFieldVal(idField, row);
				return dataDF.displayLink($scope, row, field, url);
			},
			getClass: function () {
				return 'd-link';
			}
		};
	}
	
	function getAuthObj(field) {
		return {
			field: field,
			title: '授權',
			getValue: function ($scope, row) {
				let authCode = row[field];
				return getAuthLabel(authCode);
			},
			sortable: 'auth'
		};
	}

	function displayOptions($scope, row) {
		let prop = 'categories',
			valProp = '_id',
			labelProp = 'name';
		return dataDF.displayOptions($scope, row, prop, valProp, labelProp);
	}

	// ---------- setting ---------- //

	_factory.setting = {};

	_factory.setting.manager = [
		getAvatarObj("avatar"),
		{
			field: "name",
			title: "名稱",
			sortable: "name",
			getValue: dataDF.displaySub
		}, {
			field: "email",
			title: "電子郵件",
			getValue: dataDF.displaySub
		}, {
			field: "role.label",
			title: "權限",
			sortable: "role.label",
			getValue: dataDF.displaySub
		}, {
			field: "role.createdAt",
			title: "建立日期",
			sortable: "role.createdAt",
			getValue: dataDF.displayDateTime
		},
		getFnObj(['del'], '')
	];


	// ---------- content ---------- //

	_factory.content = {
		category: {},
		member: {},
		project: {},
		course: {},
		author: {},
		advertisement: {}
	};

	_factory.content.category.all = [
		sortObj,
		{
			field: "name",
			title: "分類",
			sortable: "name",
			getValue: dataDF.displayRaw
		}, {
			field: 'isPublic',
			title: '公開',
			getValue: dataDF.displayBool
		}, {
			field: "createdAt",
			title: "建立日期",
			sortable: "createdAt",
			getValue: dataDF.displayDateTime
		},
		fnObj
	];

	_factory.content.member.all = [
		getAvatarObj("avatar"),
		getMemberObj("name", "memberId"),
		{
			field: "categories",
			title: "分類",
			sortable: "categories",
			getValue: displayOptions
		}, {
			field: "email",
			title: "電子郵件",
			getValue: dataDF.displaySub
		}, {
			field: "role.createdAt",
			title: "加入日期",
			sortable: "role.createdAt",
			getValue: dataDF.displayDateTime
		},
		fnObj
	];
	_factory.content.member.audit = [
		getAvatarObj("avatar"),
		getMemberObj("name", "memberId"),
		// Need to check what information should be added.
		//////////////////////////////////
		//{
		//	field: "msg",
		//	title: "申請訊息",
		//	sortable: "msg",
		//	getValue: dataDF.displayRaw
		//},
		//////////////////////////////////
		{
		 	field: "school",
		 	title: "任職學校",
		 	getValue: dataDF.displayRaw
		},{
		 	field: "subject",
		 	title: "任教科目",
		 	getValue: dataDF.displayRaw
		},{
			field: "email",
			title: "電子郵件",
			getValue: dataDF.displayRaw
		},{
			field: "role.createdAt",
			title: "申請日期",
			sortable: "role.createdAt",
			getValue: dataDF.displayDateTime
		},
		getFnObj(['accept', 'reject'])
	];

	_factory.content.project.all = [
		getProjectObj('project.name', 'project._id'),
		{
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub,
			sortable: 'author.name'
		}, {
			field: 'categories',
			title: '分類',
			getValue: displayOptions,
			sortable: 'categories'
		},
		getAuthObj('auth'),
		{
			field: 'createdAt',
			title: '建立日期',
			getValue: dataDF.displayDateTime,
			sortable: 'createdAt'
		},
		fnObj
	];
	_factory.content.project.recommend = [
		// sortObj,
		idxObj,
		getProjectObj('project.name', 'project._id'),
		{
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub
		}, {
			field: 'categories',
			title: '分類',
			getValue: displayOptions
		},
		getAuthObj('auth'),
		{
			field: 'createdAt',
			title: '建立日期',
			getValue: dataDF.displayDateTime
		},
		getFnObj(['del'])
	];
	_factory.content.project.audit = [
		getProjectObj('project.name', 'project._id'),
		{
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub
		}, {
			field: 'createdAt',
			title: '投稿時間',
			getValue: dataDF.displayDateTime
		},
		getFnObj(['accept', 'reject'])
	];

	_factory.content.course.all = [
		getCourseObj('course.name', 'course._id'),
		{
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub,
			sortable: 'author.name'
		}, {
			field: 'categories',
			title: '分類',
			getValue: displayOptions,
			sortable: 'categories'
		},
		getAuthObj('auth'),
		{
			field: 'createdAt',
			title: '建立日期',
			getValue: dataDF.displayDateTime,
			sortable: 'createdAt'
		},
		fnObj
	];
	_factory.content.course.recommend = [
		// sortObj,
		idxObj,
		getCourseObj('course.name', 'course._id'), {
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub
		}, {
			field: 'categories',
			title: '分類',
			getValue: displayOptions
		},
		getAuthObj('auth'),
		{
			field: 'createdAt',
			title: '建立日期',
			getValue: dataDF.displayDateTime
		},
		getFnObj(['del'])
	];
	_factory.content.course.audit = [
		getCourseObj('course.name', 'course._id'),
		{
			field: 'author.name',
			title: '作者',
			getValue: dataDF.displaySub,
			sortable: 'author.name'
		}, {
			field: 'createdAt',
			title: '投稿時間',
			getValue: dataDF.displayDateTime,
			sortable: 'createdAt'
		},
		getFnObj(['accept', 'reject'])
	];

	_factory.content.author.all = [
		getAvatarObj("avatar"),
		{
			field: "name",
			title: "名稱",
			getValue: dataDF.displaySub
		}, {
			field: "categories",
			title: "分類",
			sortable: "categories",
			getValue: displayOptions
		}, {
			field: "email",
			title: "電子郵件",
			getValue: dataDF.displaySub
		}, {
			field: "role.createdAt",
			title: "加入日期",
			sortable: "createdAt",
			getValue: dataDF.displayDateTime
		},
		fnObj
	];
	_factory.content.author.audit = [
		getAvatarObj("avatar"),
		{
			field: "name",
			title: "名稱",
			getValue: dataDF.displaySub
		},
		// Need to check what information should be added.
		//////////////////////////////////
		// {
		// 	field: "school",
		// 	title: "學校",
		// 	getValue: dataDF.displayRaw
		// }, {
		// 	field: "subject",
		// 	title: "任教科目",
		// 	getValue: dataDF.displayRaw
		// },
		//////////////////////////////////
		{
			field: "email",
			title: "電子郵件",
			getValue: dataDF.displaySub
		},{
			field: "msg",
			title: "申請訊息",
			sortable: "msg",
			getValue: dataDF.displayRaw
		}, {
			field: "role.createdAt",
			title: "申請日期",
			sortable: "role.createdAt",
			getValue: dataDF.displayDateTime
		},
		getFnObj(['accept', 'reject'])
	];

	_factory.content.advertisement.home = [
		// sortObj,
		idxObj,
		thumbObj,
		{
			field: "name",
			title: "名稱",
			sortable: "name",
			getValue: dataDF.displayRaw
		},
		{
			field: "linkUrl",
			title: "網址",
			sortable: "linkUrl",
			getValue: dataDF.displayRaw
		}, {
			field: "createdAt",
			title: "建立日期",
			sortable: "createdAt",
			getValue: dataDF.displayDateTime
		},
		fnObj
	];
	_factory.content.advertisement.side = [
		// sortObj,
		idxObj,
		thumbObj,
		{
			field: "name",
			title: "名稱",
			sortable: "name",
			getValue: dataDF.displayRaw
		},
		{
			field: "linkUrl",
			title: "網址",
			sortable: "linkUrl",
			getValue: dataDF.displayRaw
		}, {
			field: "createdAt",
			title: "建立日期",
			sortable: "createdAt",
			getValue: dataDF.displayDateTime
		},
		fnObj
	];

	// ---------- analytics ---------- //

	_factory.analytics = {
		overview: {},
		user: {},
		content: {}
	};

	_factory.analytics.overview = [{
		name: '分類概覽',
		cols: [
			rankObj,
			{
				field: "name",
				title: "分類名稱",
				getValue: dataDF.displayRaw
			},
			{
				field: "viewCount",
				title: "觀看數",
				class: 'd-fit',
				getValue: dataDF.displayRaw,
				getClass: function () {
					return 'd-fit';
				}
			},
			barObj
		],
		order: 1
	}, {
		name: '專案概況',
		cols: [
			getProjectObj('name', '_id'),
			{
				field: 'viewCount',
				title: '觀看數',
				sortable: "viewCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'likeCount',
				title: '收藏數',
				sortable: "likeCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'cloneCount',
				title: '複製數',
				sortable: "cloneCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'author.name',
				title: '作者',
				sortable: "author.name",
				getValue: dataDF.displaySub
			}
		],
		order: 2
	}, {
		name: '課程概況',
		cols: [
			getCourseObj('name', '_id'), {
				field: 'viewCount',
				title: '觀看數',
				sortable: "viewCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'likeCount',
				title: '收藏數',
				sortable: "likeCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'cloneCount',
				title: '複製數',
				sortable: "cloneCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'author.name',
				title: '作者',
				sortable: "author.name",
				getValue: dataDF.displaySub
			}
		],
		order: 3
	}];

	_factory.analytics.user.overview = [];
	_factory.analytics.user.user = [{
		name: '學科概況',
		cols: [
			rankObj,
			{
				field: "name",
				title: "分類名稱",
				getValue: dataDF.displayRaw
			},
			{
				field: "viewCount",
				title: "觀看數",
				class: 'd-fit',
				getValue: dataDF.displayRaw,
				getClass: function () {
					return 'd-fit';
				}
			},
			barObj
		]
	}];
	_factory.analytics.user.interest = [{
		name: '專案收藏排行',
		cols: [
			idxObj,
			getProjectObj('name', '_id'),
			{
			field: "likeCount",
			title: "收藏數",
			sortable: "likeCount",
			getValue: dataDF.displayRaw
		}, {
			field: "author.name",
			title: "作者",
			sortable: "author.name",
			getValue: dataDF.displaySub
		}],
		order: 1
	}, {
		name: '專案複製排行',
		cols: [idxObj, getProjectObj('name', '_id'), {
			field: "cloneCount",
			title: "複製數",
			sortable: "cloneCount",
			getValue: dataDF.displayRaw
		}, {
			field: "author.name",
			title: "作者",
			sortable: "author.name",
			getValue: dataDF.displaySub
		}],
		order: 2
	}, {
		name: '課程收藏排行',
		cols: [idxObj, getCourseObj('name', '_id'), {
			field: "likeCount",
			title: "收藏數",
			sortable: "likeCount",
			getValue: dataDF.displayRaw
		}, {
			field: "author.name",
			title: "作者",
			sortable: "author.name",
			getValue: dataDF.displaySub
		}],
		order: 3
	}, {
		name: '課程複製排行',
		cols: [idxObj, getCourseObj('name', '_id'),
			{
			field: "cloneCount",
			title: "複製數",
			sortable: "cloneCount",
			getValue: dataDF.displayRaw
		}, {
			field: "author.name",
			title: "作者",
			sortable: "author.name",
			getValue: dataDF.displaySub
		}],
		order: 4
	}];

	_factory.analytics.content.project = [{
		name: '全部專案',
		cols: [
			getProjectObj('name', '_id'), {
				field: 'viewCount',
				title: '觀看數',
				sortable: "viewCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'likeCount',
				title: '收藏數',
				sortable: "likeCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'cloneCount',
				title: '複製數',
				sortable: "cloneCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'author.name',
				title: '作者',
				sortable: "author.name",
				getValue: dataDF.displaySub
			}
		]
	}];
	_factory.analytics.content.course = [{
		name: '全部課程',
		cols: [
			getCourseObj('name', '_id'), {
				field: 'viewCount',
				title: '觀看數',
				sortable: "viewCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'likeCount',
				title: '收藏數',
				sortable: "likeCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'cloneCount',
				title: '複製數',
				sortable: "cloneCount",
				getValue: dataDF.displayRaw
			}, {
				field: 'author.name',
				title: '作者',
				sortable: "author.name",
				getValue: dataDF.displaySub
			}
		]
	}];
	_factory.analytics.content.author = [{
		name: '全部作者',
		cols: [
			avatarObj,
			getMemberObj("name", "memberId"),
			{
				field: "projectCounts",
				title: "專案數量",
				getValue: dataDF.displayRaw
			}, {
				field: "courseCounts",
				title: "課程數量",
				getValue: dataDF.displayRaw
			}, {
				field: "createdAt",
				title: "加入日期",
				getValue: dataDF.displayDateTime
			}
		]
	}];
	_factory.analytics.content.ad = [{
		name: '全部廣告',
		cols: [
			thumbObj, {
				field: "name",
				title: "名稱",
				getValue: dataDF.displayRaw
			}, {
				field: "exposureNum",
				title: "曝光次數",
				getValue: dataDF.displayRaw
			}, {
				field: "clickNum",
				title: "點擊次數",
				getValue: dataDF.displayRaw
			}, {
				field: "clickRate",
				title: "點擊率",
				getValue: dataDF.displayRaw
			}
		]
	}];

	return _factory;
});
