/**
 * Created by Tinny Wang on 2017/8/22.
 */

app.factory('OrgFactory', function ($q, $http, Upload) {
	
	var _factory = {};
	
	function _getApi(orgId, suffix) {
		let api = '/api/org/' + orgId;
		if (suffix){
			api += '/' + suffix;
		}
		return api;
	}

	function _getInfoApi(orgId, suffix) {
		let info = 'info';
		info += suffix ? '/' + suffix : '';
		return _getApi(orgId, info);
	}

	_factory.info = function (orgId) {
		return $http.get(_getInfoApi(orgId));
	};

	_factory.update = function (orgId, params) {
		var defaultParams = angular.extend({
			name: undefined,
			description: undefined,
			avatar: undefined,
			website: undefined,
			facebook: undefined,
			googlePlus: undefined,
			youtube: undefined,
			color: undefined
		}, (params || {}));
		return $http.put(_getInfoApi(orgId), defaultParams);
	};

	_factory.uploadAvatar = function(orgId, file) {
		return Upload.upload({
			url: _getInfoApi(orgId, 'upload-avatar'),
			data: { file: file },
		});
	};
	
	_factory.follow = function (orgId) {
		return $http.post(_getApi(orgId, 'follow'));
	};
	
	_factory.unfollow = function (orgId) {
		return $http.delete(_getApi(orgId, 'unfollow'));
	};

	return _factory;
	
});
