/**
 * Created by Tinny Wang on 2017/9/15.
 */

app.factory('OrgMemberFactory', function($http){
	var _factory = {};
	
	function _getApi(orgId, role, suffix) {
		let api = '/api/org/' + orgId + '/' + role;
		if (suffix){
			api += '/' + suffix;
		}
		return api;
	}
	
	function getMemberApi(orgId, suffix) {
		return _getApi(orgId, 'member', suffix);
	}

	function getAuthorApi(orgId, suffix) {
		return _getApi(orgId, 'author', suffix);
	}

	function getOtherAdminApi(orgId, suffix) {
		return _getApi(orgId, 'other-admin', suffix);
	}
	
	function getIsoDateStr(date) {
		return date.toISOString().split('T')[0];
	}

	_factory.list = function (orgId, params) {
		var defaultParams = angular.extend({
			status: 'VERIFIED',
			perPage: 5000
		}, (params || {}));
		return $http.get(getMemberApi(orgId), {
			params: defaultParams
		});
	};

	_factory.listAuthor = function (orgId, params) {
		var defaultParams = angular.extend({
			status: 'VERIFIED',
			perPage: 5000
		}, (params || {}));
		return $http.get(getAuthorApi(orgId), {
			params: defaultParams
		});
	};

	_factory.listOtherAdmin = function (orgId, params) {
		var defaultParams = angular.extend({
			status: 'VERIFIED',
			perPage: 5000
		}, (params || {}));
		return $http.get(getOtherAdminApi(orgId), {
			params: defaultParams
		});
	};
	
	_factory.create = function (orgId, memberId, params) {
		var defaultParams = angular.extend({
			member: memberId,
			categories: []
		}, (params || {}));
		console.log(defaultParams);
		return $http.post(getMemberApi(orgId), defaultParams);
	};

	_factory.createAuthor = function (orgId, memberId, params) {
		var defaultParams = angular.extend({
			member: memberId
		}, (params || {}));
		return $http.post(getAuthorApi(orgId), defaultParams);
	};

	_factory.createOtherAdmin = function (orgId, memberId, params) {
		var defaultParams = angular.extend({
			member: memberId
		}, (params || {}));
		return $http.post(getOtherAdminApi(orgId), defaultParams);
	};

	_factory.update = function (orgId, o_mId, params) {
		var defaultParams = angular.extend({
			categories: []
		}, (params || {}));
		return $http.put(getMemberApi(orgId, o_mId), defaultParams);
	};

	_factory.updateAuthor = function (orgId, o_mId, params) {
		var defaultParams = angular.extend({
			categories: []
		}, (params || {}));
		return $http.put(getAuthorApi(orgId, o_mId), defaultParams);
	};

	_factory.remove = function (orgId, o_mId) {
		return $http.delete(getMemberApi(orgId, o_mId));
	};

	_factory.removeAuthor = function (orgId, o_mId) {
		return $http.delete(getAuthorApi(orgId, o_mId));
	};

	_factory.removeOtherAdmin = function (orgId, o_mId) {
		return $http.delete(getOtherAdminApi(orgId, o_mId));
	};
	
	// member info is caught from session
	_factory.apply = function (orgId,applicant) {
		console.log(applicant);
		return $http.post(getMemberApi(orgId, 'apply'),applicant);
	};

	// member info is caught from session
	_factory.applyAuthor = function (orgId) {
		return $http.post(getAuthorApi(orgId, 'apply'));
	};
	
	_factory.accept = function (orgId, o_mId) {
		return $http.put(getMemberApi(orgId, o_mId + '/approve'));
	};

	_factory.acceptAuthor = function (orgId, o_mId) {
		return $http.put(getAuthorApi(orgId, o_mId + '/approve'));
	};

	_factory.reject = function (orgId, o_mId) {
		return $http.delete(getMemberApi(orgId, o_mId));
		// return $http.put(getMemberApi(orgId, o_mId + '/reject'));
	};

	_factory.rejectAuthor = function (orgId, o_mId) {
		return $http.delete(getAuthorApi(orgId, o_mId));
		// return $http.put(getAuthorApi(orgId, o_mId + '/reject'));
	};
	
	_factory.memberRatio = function (orgId) {
		return $http.get(_getApi(orgId, 'member', 'ratio'));
	};

	_factory.memberStat = function (orgId) {
		return $http.get(_getApi(orgId, 'member', 'stat'));
	};

	_factory.memberStatTime = function (orgId, params) {
		var defaultRange = 30 * 24 * 60 * 60 * 1000, // 30 days
			endTime = new Date(),
			startTime = new Date(endTime.getTime() - defaultRange);
		var defaultParams = angular.extend({
			start: getIsoDateStr(startTime),
			end: getIsoDateStr(endTime)
		}, (params || {}));
		return $http.get(_getApi(orgId, 'member', 'stat/time-series'), {
			params: defaultParams
		});
	};

	_factory.authorStat = function (orgId) {
		return $http.get(_getApi(orgId, 'author', 'stat'));
	};

	_factory.authorStatList = function (orgId, params) {
		var defaultParams = angular.extend({
			sort_type: '-projectCounts',
			page: 1,
			perpage: 5000
		}, (params || {}));
		return $http.get(_getApi(orgId, 'author', 'stat/list'), {
			params: defaultParams
		});
	};
	
	return _factory;
});
