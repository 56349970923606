app.factory('RedeemFactory', ['$http', '$q', function($http, $q) {

        var _factory = {};

        _factory.checkRedeem = function(courseId) {
            var defer = $q.defer();
            var url = "/api/redeem/checkRedeem/?courseId=" + courseId;
            $http.get(url).then(function(data) {
                _factory.checkRedeem = data.data;
                defer.resolve(_factory.checkRedeem);
            });
            return defer.promise;
        };

        _factory.create = function(courseId, redeemCode) {
            return $http.post("/api/redeem/create", {
                redeemCode: redeemCode,
                courseId: courseId
            });
        };

        _factory.list = function() {
            var defer = $q.defer();
            var url = "/api/redeem/list/";
            $http.get(url).then(function(data) {
                defer.resolve(data.data);
            });
            return defer.promise;
        };


        _factory.checkRedeemState = function(courseId) {
            var defer = $q.defer();
            var url = "/api/redeem/checkCourseRedeemState/?courseId=" + courseId;
            $http.get(url).then(function(data) {
                _factory.checkRedeem = data.data;
                defer.resolve(_factory.checkRedeem);
            });
            return defer.promise;
        };



        // app.post('/api/redeem/create', redeem.create);
        // app.get('/api/redeem/checkRedeem', redeem.checkRedeem);



        return _factory;
    }]);
