/**
 * Created by Tinny Wang on 2017/11/23.
 */

app.factory('ShareItemFactory', function (TicketFactory, MyCourseFactory, MyProjectFactory, OrgCourseFactory, OrgProjectFactory) {
	var _factory = {
		Course: {},
		Project: {},
	};

	//-- Course
	_factory.Course.getItems = function () {
		return MyCourseFactory.getCourses();
	};

	_factory.Course.updateItem = function (params) {
		return MyCourseFactory.update(params);
	};

	_factory.Course.uploadThumbnail = function (file) {
		return MyCourseFactory.uploadThumbnail(file);
	};

	_factory.Course.shareApply = function (sharePublic, params) {
		if (!sharePublic) {
			return OrgCourseFactory.apply(params.orgId, params.itemId, {});
		} else {
			return TicketFactory.applyPublicCourse({
				courseId: params.itemId
			});
		}
	};

	//-- Project
	_factory.Project.getItems = function () {
		return MyProjectFactory.getProjects();
	};
	
	_factory.Project.updateItem = function (params) {
		return MyProjectFactory.update(params);
	};
	
	_factory.Project.uploadThumbnail = function (file) {
		return MyProjectFactory.uploadThumbnail(file);
	};
	
	_factory.Project.shareApply = function (sharePublic, params) {
		if (!sharePublic) {
			return OrgProjectFactory.apply(params.orgId, params.itemId, {});
		} else {
			//-- 需審核（API未實作）
			// return TicketFactory.applyPublicProject({
			// 	projectId: params.itemId
			// });
			//-- 不需審核直接公開
			return MyProjectFactory.setPublic({
				id: params.itemId,
				isPublic: true
			});
		}
	};
	
	return _factory;
});
