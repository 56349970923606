app
    .factory('ExploreFactory', ['$http', '$q', function($http, $q) {

        var _factory = {};


        _factory.searchPublic = function(start, len, keyword, orderBy) {
            return $http.get("api/project/list/?start=" + start + "&len=" + len + "&keyword=" + keyword + "&isDeleted=false&isPublic&sort=" + orderBy);
        };

        _factory.getPromoteProjects = function(start, len, orderBy) {
            var defer = $q.defer();
            var url = "/api/promote/list";
            $http.get(url).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
            return defer.promise;
        };




        _factory.listProjects = function(orderBy) {
            var defer = $q.defer();
            var url = "api/project/list/?start=0&len=500&isDeleted=false&isPublic&sort="+orderBy;
            $http.get(url).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
            return defer.promise;
        };



        return _factory;
    }]);
