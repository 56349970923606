app.factory('FolderFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};
    var defer = $q.defer();
    _factory.folders = [];

    UserFactory.getUser().then(function() {
        $http.get("api/folder/list/?start=0&len=500").then(function(data) {
            _factory.folders = data.data;
            defer.resolve(_factory.folders);
        });
    });

    _factory.getFolders = function() {
        return defer.promise;
    };

    _factory.create = function(params) {
        return $http.post("api/folder/create", params);
    };

    _factory.rename = function(params) {
        return $http.post("api/folder/update", params);
    };

    _factory.remove = function(params) {
        return $http.post("api/folder/remove", params);
    };

    _factory.addProjects = function (params) {
        return $http.post("api/folder/add-projects", params);
    };

    _factory.removeProjects = function (folderId, projectIds) {
        return $http.post("api/folder/remove-projects", {
            folderId: folderId,
            projectIds: projectIds.join()
        });
    };

    _factory.listProjects = function (folderId) {
        var defer = $q.defer();

        $http.get("api/folder/list-projects/?start=0&len=500&id=" + folderId).then(function(data) {
            _factory.projects = data.data;
            defer.resolve(_factory.projects);
        });
        
        return defer.promise;
    };

    return _factory;
});
