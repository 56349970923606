app.factory('MyCourseFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};

    _factory.getTrash = function() {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            $http.get("/api/course/list/?isDeleted=true&authorId=" + UserFactory.user._id).then(function(data) {
                _factory.courses = data.data;
                defer.resolve(_factory.courses);
            });
        });
        return defer.promise;
    };

    _factory.getCourses = function(isPublic) {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            // var url = "api/course/list/?start=0&len=500&isDeleted=false&authorId=" + UserFactory.user._id;
            var url = "/api/course/list/?&start=0&len=500&isDeleted=false&authorId=" + UserFactory.user._id;
            if (isPublic) {
                url += "&isPublic";
            }
            $http.get(url).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
        });
        return defer.promise;
    };

    // _factory.getInvitedProjects = function() {
    //     var defer = $q.defer();
    //     UserFactory.getUser().then(function() {
    //         $http.get("api/invite/list-invited-projects?email=" + UserFactory.user.email).then(function(data) {
    //             _factory.projects = data.data;
    //             defer.resolve(_factory.projects);
    //         });
    //     });
    //     return defer.promise;
    // };

    _factory.create = function(params) {
        return $http.post("/api/course/create", params);
    };

    _factory.update = function(params) {
        return $http.post("/api/course/update", params);
    };



    _factory.setPublic = function(params) {
        return $http.post("/api/project/set-public", params);
    };

    _factory.moveToTrash = function(params) {
        return $http.post("/api/course/move-to-trash", params);
    };

    _factory.restore = function(params) {
        return $http.post("/api/course/restore", params);
    };

    _factory.remove = function(params) {
        return $http.post("/api/course/remove", params);
    };

    _factory.emptyTrash = function() {
        return $http.post("/api/course/empty-trash");
    };

    _factory.clone = function(course, newName, newDescription, folderId) {
        return $http.post("/api/course/clone", {
            id: course._id,
            name: newName || course.name,
            description: newDescription || course.description,
            folderId: folderId
        });
    };

    _factory.uploadThumbnail = function(file) {
        return Upload.upload({
            url: '/api/course/upload-thumbnail',
            data: { file: file },
        });
    };

	_factory.uploadAttachment = function (id, file) {
		return Upload.upload({
			url: '/api/course/' + id + '/upload-attachment',
			data: {
				file: file
			},
		});
	};

    return _factory;
});
