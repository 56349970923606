app.factory('InviteFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};

    _factory.send = function (projects, receivers, inviteIds) {
        var receiverEmails = receivers.map(function (r) {
            return r.email;
        });
        var projectIds = projects.map(function (p) {
            return p._id;
        });

        $http.post("/api/invite/remove-invites", {
            inviteIds: inviteIds.join()
        });

        // createInvites
        return $http.post("/api/invite/create-invites", {
            emails: receiverEmails.join(),
            projectIds: projectIds.join()
        });

    };

    _factory.listInvites = function (projectId) {
        return $http.get("/api/invite/list-invites?projectId=" + projectId);
    };

    _factory.acceptInivte = function (inviteId) {
        return $http.post("/api/invite/accept", {
            id: inviteId
        });
    };

    _factory.rejectInivte = function (inviteId) {
        return $http.post("/api/invite/reject", {
            id: inviteId
        });
    };

    return _factory;
});