/**
 * Created by Tinny Wang on 2017/12/19.
 */
app.factory('AdFactory', function ($http) {
	var _factory = {};

	_factory.readmoo = function (params) {
		var defaultParams = angular.extend({
			client_id: 'bpk19f0qc5i4ezjhvn87mwx6glyubpk1',
			count: 1,
			offset: 0
		}, (params || {}));
		return $http.get('https://api.readmoo.com/v2/books', {
			params: defaultParams
		});
	};
	
	return _factory;
});
