app
    .factory('ProjectFactory', ['$http', '$q', function($http, $q) {

        var _factory = {};

        _factory.info = function(projectId) {
            return $http.get("/api/project/info/" + projectId);
        };

        _factory.searchPublic = function(start, len, keyword, orderBy) {
            return $http.get("/api/project/list/?start=" + start + "&len=" + len + "&keyword=" + keyword + "&isDeleted=false&isPublic&sort=" + orderBy);
        };

        _factory.getPromoteProjects = function(start, len, orderBy) {
            return $http.get("/api/promote/list");
        };

        _factory.listFollowedProjects = function() {
            return $http.get("/api/project/list-followed-projects");
        };


        _factory.getCategoryProjects = function(categoryId, start, len, orderBy) {
            return $http.get("/api/project/list/?start=" + start + "&len=" + len + "&isDeleted=false&isPublic&categories=" + categoryId + "&sort=" + orderBy);
        };

        _factory.getFollowedProjects = function(start, len, orderBy) {
            return $http.get("/api/project/list-followed-projects/?start=" + start + "&len=" + len + "&isDeleted=false&isPublic&sort=" + orderBy);
        };

        _factory.getUserProjects = function(authorId, start, len, orderBy) {
            return $http.get("/api/project/list/?start=" + start + "&len=" + len + "&isDeleted=false&isPublic&authorId=" + authorId + "&sort=-viewCount");
        };

        _factory.getAuthorProjects = function(authorId) {
            var defer = $q.defer();
            var url = "/api/project/list/?start=0&len=500&isDeleted=false&isPublic&authorId=" + authorId;
            $http.get(url).then(function(data) {
                _factory.projects = data.data;
                defer.resolve(_factory.projects);
            });
            return defer.promise;
        };

        _factory.listLikeProjects = function(authorId) {
            var defer = $q.defer();
            var url = "/api/project/list-liked-projects/?id=" + authorId;
            $http.get(url).then(function(data) {
                _factory.likeProjects = data.data;
                defer.resolve(_factory.likeProjects);
            });
            return defer.promise;
        };

        _factory.like = function(projectId) {
            return $http.post("/api/project/like", {
                id: projectId
            });
            // TDAPP.onEvent("Project-Like", projectId);
        };

        _factory.unlike = function(projectId) {
            return $http.post("/api/project/unlike", {
                id: projectId
            });
            // TDAPP.onEvent("Project-UnLike", projectId);
        };
		
		_factory.getDownloadAttachmentUrl = function (projectId, spKey, fileName) {
			var url = [
				'/api/project/' + projectId + '/download-attachment',
				'?key=' + spKey,
				'&fileName=' + fileName
			];
			return url.join('');
		};

        return _factory;
    }]);
