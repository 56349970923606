app.factory('CourseFactory', function($q, $http) {

    var _factory = {};

    _factory.info = function(id) {
        return $http.get("/api/course/info" + id);
    };

    _factory.getChapters = function(id) {
        return $http.get("/api/course/list-chapters?id=" + id);
    };

    _factory.info = function(id) {
        return $http.get("/api/course/info/" + id);
    };

    _factory.countCourses = function() {
        return $http.get("/api/course/countCourses/");
    };

    _factory.createChapter = function(params) {
        return $http.post("/api/chapter/create", params);
    };

    _factory.updateChapter = function(params) {
        return $http.post("/api/chapter/update", params);
    };

    _factory.removeChapter = function(params) {
        return $http.post("/api/chapter/remove", params);
    };

    _factory.chapterAddProjects = function(params) {
        return $http.post("/api/chapter/update-projects", params);
    };

    _factory.updateCourses = function(params) {
        return $http.post("/api/course/update-chapters", params);

    };

    _factory.getAuthorCourses = function(authorId) {
        var defer = $q.defer();
        var url = "/api/course/list/?&isDeleted=false&isPublic&authorId=" + authorId;
        $http.get(url).then(function(data) {
            _factory.courses = data.data;
            defer.resolve(_factory.courses);
        });

        return defer.promise;
    };

	// _factory.listLikeProjects = function(authorId) {
	// 	var defer = $q.defer();
	// 	var url = "/api/project/list-liked-projects/?id=" + authorId;
	// 	$http.get(url).then(function(data) {
	// 		_factory.likeProjects = data.data;
	// 		defer.resolve(_factory.likeProjects);
	// 	});
	// 	return defer.promise;
	// };

	_factory.listLikeCourses = function(authorId) {
		var defer = $q.defer();
		var url = "/api/course/list-liked-courses/?id=" + authorId;
		$http.get(url).then(function(data) {
			_factory.likeCourses = data.data;
			defer.resolve(_factory.likeCourses);
		});
		return defer.promise;
	};

    _factory.like = function(id) {
        return $http.post("/api/course/like", {
            id: id
        });
    };

    _factory.unlike = function(id) {
        return $http.post("/api/course/unlike", {
            id: id
        });
    };

	_factory.getDownloadAttachmentUrl = function (projectId, spKey, fileName) {
		var url = [
			'/api/course/' + projectId + '/download-attachment',
			'?key=' + spKey,
			'&fileName=' + fileName
		];
		return url.join('');
	};

    return _factory;
});
