app.factory('SealedCourseFactory', function($q, $http) {

    var _factory = {};

    _factory.info = function(id) {
        return $http.get("/api/sealed-course/info/" + id);
    };

    _factory.getChapters = function(id) {
        return $http.get("/api/sealed-course/list-chapters?id=" + id);
    };

    _factory.like = function(id) {
        return $http.post("/api/sealed-course/like", {
            id: id
        });
    };

    _factory.unlike = function(id) {
        return $http.post("/api/sealed-course/unlike", {
            id: id
        });
    };

    _factory.clone = function(course, newName, newDescription, folderId) {
        return $http.post("/api/sealed-course/clone-to-course", {
            id: course._id,
            name: newName || course.name,
            description: newDescription || course.description,
            folderId: folderId
        });
    };
		
		_factory.listLikeCourses = function(authorId) {
			var defer = $q.defer();
			var url = "/api/sealed-course/list-liked-courses/?id=" + authorId;
			$http.get(url).then(function(data) {
				_factory.likeCourses = data.data;
				defer.resolve(_factory.likeCourses);
			});
			return defer.promise;
		};


    return _factory;
});
