app
    .factory('SealedProjectFactory', ['$http', '$q', function($http, $q) {

        var _factory = {};

        _factory.info = function(projectId) {
            return $http.get("/api/sealed-project/info/" + projectId);
        };

        _factory.getPages = function(projectId, start, len) {
            if (start !== undefined) {
                return $http.get("/api/sealed-project/list-pages/?id=" + projectId + "&start=" + start + "&len=" + len);
            }
            else {
                return $http.get("/api/sealed-project/list-pages/?id=" + projectId);
            }
        };

        _factory.like = function(projectId) {
            return $http.post("/api/sealed-project/like", {
                id: projectId
            });
            // TDAPP.onEvent("Project-Like", projectId);
        };

        _factory.unlike = function(projectId) {
            return $http.post("/api/sealed-project/unlike", {
                id: projectId
            });
            // TDAPP.onEvent("Project-UnLike", projectId);
        };

        _factory.clone = function(project, newName, newDescription, folderId) {
            return $http.post("/api/sealed-project/clone-to-project", {
                id: project._id,
                name: newName || project.name,
                description: newDescription || project.description,
                folderId: folderId
            });
        };
				
				_factory.listLikeProjects = function(authorId) {
					var defer = $q.defer();
					var url = "/api/sealed-project/list-liked-projects/?id=" + authorId;
					$http.get(url).then(function(data) {
						_factory.likeProjects = data.data;
						defer.resolve(_factory.likeProjects);
					});
					return defer.promise;
				};
				
        return _factory;
    }]);
