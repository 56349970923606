app.factory('InviteCourseFactory', function($q, $http, UserFactory, Upload) {

    var _factory = {};


    _factory.send = function (courses, receivers, inviteIds) {
        var receiverEmails = receivers.map(function (r) {
            return r.email;
        });
        var courseIds = courses.map(function (c) {
            return c._id;
        });
        $http.post("/api/invite-course/remove", {
            inviteIds: inviteIds.join()
        });

        // createInvites
        return $http.post("/api/invite-course/create", {
            userIds:inviteIds.join(),
            emails: receiverEmails.join(),
            courseIds: courseIds.join()
        });

    };

    // _factory.listInvites = function (projectId) {
    //     return $http.get("/api/invite/list-invites?projectId=" + projectId);
    // };

    // _factory.listInvites = function (email) {
    //     return $http.get("/api/invite-course/list-courses?email="+email);
    // };

    _factory.listInvites = function() {
        var defer = $q.defer();
        UserFactory.getUser().then(function() {
            $http.get("/api/invite-course/list-courses?email=" + UserFactory.user.email).then(function(data) {
                defer.resolve(data);
            });
        });
        return defer.promise;


    };
    

    _factory.acceptInivte = function (inviteId) {
        return $http.post("/api/invite-course/accept", {
            id: inviteId
        });
    };

    _factory.rejectInivte = function (inviteId) {
        return $http.post("/api/invite-course/reject", {
            id: inviteId
        });
    };

    return _factory;
});