/**
 * Created by Tinny Wang on 2017/9/21.
 */

app.factory('OrgCategoryFactory', function($http){
	var _factory = {};

	function _getApi(orgId, suffix) {
		let api = '/api/org/' + orgId + '/category';
		if (suffix){
			api += '/' + suffix;
		}
		return api;
	}

	_factory.list = function (orgId, params) {
		var defaultParams = angular.extend({
			perPage: 5000
		}, (params || {}));
		return $http.get(_getApi(orgId), {
			params: defaultParams
		});
	};

	_factory.create = function (orgId, params) {
		var defaultParams = angular.extend({
			name: undefined,
			isPublic: undefined
		}, (params || {}));
		return $http.post(_getApi(orgId), defaultParams);
	};

	_factory.update = function (orgId, categoryId, params) {
		var defaultParams = angular.extend({
			name: undefined,
			isPublic: undefined
		}, (params || {}));
		return $http.put(_getApi(orgId, categoryId), defaultParams);
	};

	_factory.remove = function (orgId, categoryId) {
		return $http.delete(_getApi(orgId, categoryId));
	};
	
	_factory.statList = function (orgId, params) {
		var defaultParams = angular.extend({
			sort_type: '-viewCount',
			page: 1,
			perpage: 5000
		}, (params || {}));
		return $http.get(_getApi(orgId, 'stat/list'), {
			params: defaultParams
		});
	};

	return _factory;
});
