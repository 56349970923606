/**
 * Created by Tinny Wang on 2017/12/1.
 */

app.factory('OrgAdFactory', function ($http, Upload) {
	var _factory = {};

	function _getApi(orgId, suffix) {
		let api = '/api/org/' + orgId + '/ad';
		if (suffix){
			api += '/' + suffix;
		}
		return api;
	}

	_factory.list = function (orgId, type) {
		return $http.get(_getApi(orgId), {
			params: {
				type: type
			}
		});
	};
	
	_factory.create = function (orgId, type, params) {
		var defaultParams = angular.extend({
			type: type,
			name: undefined,
			linkUrl: undefined,
			image: {
				url: undefined
			},
			bkgColor: undefined,
			description: undefined
		}, (params || {}));
		return $http.post(_getApi(orgId), defaultParams);
	};
	
	_factory.update = function (orgId, adId, params) {
		var defaultParams = angular.extend({
			name: undefined,
			linkUrl: undefined,
			image: {
				url: undefined
			},
			bkgColor: undefined,
			description: undefined
		}, (params || {}));
		return $http.put(_getApi(orgId, adId), defaultParams);
	};
	
	_factory.remove = function (orgId, adId) {
		return $http.delete(_getApi(orgId, adId));
	};

	_factory.uploadImg = function (file, params) {
		return Upload.upload({
			url: _getApi(params.orgId, 'upload-img'),
			data: {file: file},
		});
	};
	
	return _factory;
});
