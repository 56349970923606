/**
 * Created by Tinny Wang on 2017/10/19.
 */

app.factory('OrgFactory', function ($q, $http) {
	var _factory = {};
	
	_factory.listProjects = function (categoryId, params) {
		var defaultParams = angular.extend({
			_id: categoryId,
			len: 8,
			page: 1
		}, (params || {}));
		return $http.post('/api/org/list-projects', defaultParams);
	};

	_factory.listCourses = function (categoryId, params) {
		var defaultParams = angular.extend({
			_id: categoryId,
			len: 8,
			page: 1
		}, (params || {}));
		return $http.post('/api/org/list-courses', defaultParams);
	};
	
	return _factory;
});
