app.factory('UserFactory', function ($q, $http, $ngModal, Upload) {

	var _factory = {
		user: {},
		ready: false
	};

	var defer = $q.defer();

	$http.get("/api/member/info").then(function (data) {
		_factory.user = data.data;
		_factory.ready = true;
		defer.resolve(_factory.user);
	});

	_factory.uploadAvatar = function (file) {
		return Upload.upload({
			url: '/api/member/upload-avatar',
			data: {file: file},
		});
	};

	_factory.saveProfile = function (data) {
		return $http.post("/api/member/update", data);
	};

	_factory.uploadBanner = function (file) {
		return Upload.upload({
			url: '/api/member/upload-thumbnail',
			data: {file: file},
		});
	};

	_factory.updateTutorial = function (updateInfo) {
		console.log('updateInfo true', updateInfo);
		return $http.post("/api/member/update", updateInfo);
	};

	_factory.getUser = function () {
		return defer.promise;
	};

	_factory.info = function (id) {
		return $http.get("/api/member/info/" + id);
	};

	_factory.infoByEmail = function (email) {
		return $http.get("/api/member/info-by-email/" + email);
	};

	_factory.login = function (params) {
		params.deviceToken = window.localStorage.FIREBASE_TOKEN;
		return $http.post("/api/member/login", params);
	};

	_factory.signup = function (params) {
		params.deviceToken = window.localStorage.FIREBASE_TOKEN;
		return $http.post("/api/member/signup", params);
	};

	_factory.setOffsetY = function (userId, offsetY) {
		return $http.post("/api/member/setOffsetY", {
			id: userId,
			offsetY: offsetY
		});
	};

	_factory.logout = function () {
		$http.post("/api/member/logout", {
			deviceToken: window.localStorage.FIREBASE_TOKEN
		}).success(function (data) {
			if (!data.error) {
				// location.href = "/";
				location.reload();
			}
		});
	};

	_factory.forgetPwd = function (email) {
		return $http.post("/api/member/forgetpwd", {
			email: email
		});
	};

	_factory.resetpwd = function (email, password, token) {
		return $http.post("/api/member/resetpwd", {
			email: email,
			password: password,
			token: token
		});
	};

	_factory.follow = function (userId) {
		return $http.post("/api/member/follow", {
			id: userId
		});
	};

	_factory.unfollow = function (userId) {
		return $http.post("/api/member/unfollow", {
			id: userId
		});
	};

	_factory.listFocus = function (userId) {
		return $http.get("/api/member/list-followings/" + userId);
	};

	_factory.listFollower = function (userId) {
		return $http.get("/api/member/list-followers/" + userId);
	};
	
	_factory.listOrg = function () {
		return $http.get("/api/org", {
			params: {
				sort: '-createdAt',
				role: 'AUTHOR'
			}
		});
	};

	_factory.listSealedProjects = function () {
		return $http.get("/api/member/sealed-projects");
	};

	_factory.listSealedCourses = function () {
		return $http.get("/api/member/sealed-courses");
	};

	return _factory;
});
