/**
 * Created by Tinny Wang on 2017/10/21.
 */

app.factory('OrgCourseFactory', function ($http) {
	var _factory = {};

	function _getApi(orgId, suffix) {
		let api = '/api/org/' + orgId + '/course';
		if (suffix){
			api += '/' + suffix;
		}
		return api;
	}

	function list(orgId, params, status) {
		var defaultParams = angular.extend({
			status: status,
			perPage: 5000
		}, (params || {}));
		return $http.get(_getApi(orgId), {
			params: defaultParams
		});
	}

	_factory.listVerified = function (orgId, params) {
		return list(orgId, params, 'verified');
	};

	_factory.listPending = function (orgId, params) {
		return list(orgId, params, 'pending');
	};

	_factory.listRecommend = function (orgId, params) {
		return list(orgId, params, 'recommend');
	};

	_factory.create = function (orgId, courseId, params) {
		var defaultParams = angular.extend({
			course: courseId,
			categories: [],
			isPublic: false
		}, (params || {}));
		return $http.post(_getApi(orgId), defaultParams);
	};

	_factory.update = function (orgId, o_pId, params) {
		var defaultParams = angular.extend({
			categories: [],
			isPublic: false
		}, (params || {}));
		return $http.put(_getApi(orgId, o_pId), defaultParams);
	};

	_factory.remove = function (orgId, o_pId) {
		return $http.delete(_getApi(orgId, o_pId));
	};

	_factory.verified = function (orgId, o_pId) {
		return $http.put(_getApi(orgId, o_pId + "/verified"));
	};

	_factory.reject = function (orgId, o_pId) {
		return $http.put(_getApi(orgId, o_pId + "/reject"));
	};

	_factory.recommend = function (orgId, o_pId) {
		return $http.put(_getApi(orgId, o_pId + "/recommend"));
	};

	_factory.apply = function (orgId, courseId, params) {
		var defaultParams = angular.extend({
			course: courseId
		}, (params || {}));
		return $http.post(_getApi(orgId, "apply"), defaultParams);
	};
	
	_factory.stat = function (orgId, params) {
		var defaultParams = angular.extend({
			sort_type: '-viewCount'
		}, (params || {}));
		return $http.get(_getApi(orgId, "stat"), {
			params: defaultParams
		});
	};

	_factory.statList = function (orgId, params) {
		var defaultParams = angular.extend({
			sort_type: '-viewCount',
			page: 1,
			perpage: 5000 // TODO 暫時以前端做分頁
		}, (params || {}));
		return $http.get(_getApi(orgId, "stat/list"), {
			params: defaultParams
		});
	};
	
	return _factory;
});
