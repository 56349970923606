/**
 * Created by Tinny Wang on 2017/9/14.
 */

app.factory('DataDisplayFactory', function ($timeout, $sce, $ngModal) {

	var _factory = {};

	_factory.fnType = {
		edit: {
			type: 'edit',
			content: '編輯'
		},
		del: {
			type: 'del',
			content: '刪除'
		},
		accept: {
			type: 'accept',
			content: '接受'
		},
		reject: {
			type: 'reject',
			content: '拒絕'
		}
	};
	
	function getSubFieldVal(field, row) {
		var subFields = field.split('.'),
			value = row;
		for (var i = 0; i < subFields.length; i++) {
			value = value[subFields[i]];
		}
		return value;
	}
	
	_factory.getSubFieldVal = getSubFieldVal;

	_factory.openBlockTip = function($target, tipType) {
		tipType = tipType ? '[tip-type='+tipType+']' : '';
		
		var position = $target.offset(),
			x = position.left + $target.width() / 2,
			y = position.top;
		var $blockTip = $('.block-tip' + tipType);
		console.log($blockTip);
		
		$blockTip.addClass('show');
		$blockTip.css({
			left: x,
			top: y
		});
	};
	
	_factory.closeBlockTip = function() {
		$('.block-tip').removeClass('show');
	};

	_factory.displayRaw = function ($scope, row) {
		var val = row[this.field];
		return String(val == null || val == undefined ? '' : val ) ;
	};
	
	_factory.displaySub = function ($scope, row) {
		return getSubFieldVal(this.field, row);
	};

	_factory.displayMember = function ($scope, row) {
		var value = getSubFieldVal(this.field, row) || '/images/avatar.svg';
		var html = '<div class="avatar" style="background-image: url(' + value + ')"></div>';
		return $sce.trustAsHtml(html);
	};
	
	_factory.displayThumbnail = function ($scope, row) {
		var value = getSubFieldVal(this.field, row) || '/images/default-thumbnail.jpg';
		var html = '<div class="thumb" style="background-image: url(' + value + ')"></div>';
		return $sce.trustAsHtml(html);
	};

	_factory.displayDateTime = function ($scope, row) {
		var value = getSubFieldVal(this.field, row),
			date = new Date(value);

		var year = date.getFullYear(),
			month = date.getMonth() + 1,
			day = date.getDate(),
			hr = date.getHours(),
			min = date.getMinutes(),
			sec = date.getSeconds();

		var formats = [year, month, day, hr, min, sec];
		for (var i = formats.length - 1; i > 0; i--) {
			var format = formats[i] + '';
			if (format.length < 2) formats[i] = '0' + format;
		}

		return formats.slice(0, 3).join('-') + ' ' + formats.slice(3, 6).join(':');
	};

	_factory.displayFn = function ($scope, row, fnTypes) {
		var fnType = _factory.fnType,
			rowFnTypes = row['fnTypes'],
			html = '', type = '', disabled = '';
		
		if (!(rowFnTypes instanceof Array)) {
			rowFnTypes = fnTypes;
		}
		
		fnTypes.forEach(function (type) {
			disabled = rowFnTypes.includes(type) ? '' : 'disabled';
			html += '<span block-tip class="fn ' + disabled + '" fn-type="' + fnType[type].type + '">'
				+ fnType[type].content
				+ '</span>';
		});
		return $sce.trustAsHtml(html);
	};
	
	_factory.displayBool = function ($scope, row) {
		return row[this.field] ? '是' : '否';
	};

	_factory.displayOptions = function ($scope, row, prop, valProp, labelProp) {
		// var options = getSubFieldVal(prop, row) || [],
		var options = row[prop] || [],
			display = [];

		valProp = valProp || 'value';
		labelProp = labelProp || 'label';
		
		options.forEach(function (option) {
			display.push('<span class="display-option" value="' + option[valProp] + '">' + option[labelProp] + '</span>');
		});
		return $sce.trustAsHtml(display.join(' '));
	};

	_factory.displaySort = function ($scope, row) {
		return '<span><i class="zmdi zmdi-menu"></i><input type="hidden" value="'
			+ row[this.field] + '"/></span>';
	};
	
	_factory.displayBar = function ($scope, row) {
		var value = row[this.field];
		value = value || 0.01;
		value = value * 100;
		return '<div class="bar-container"><div class="display-bar" style="width: ' + value + '%"></div></div>';
	};
	
	_factory.displayLink = function ($scope, row, textProp, url) {
		var value = getSubFieldVal(textProp, row);
		return '<a href="' + url + '" target="_blank">' + value + '</a>';
	};

	_factory.openEditingOperator = function () {
		$ngModal.openModal('edit-operation');
	};

	_factory.fnOperator = function ($event, row) {
		var $target = $($event.target),
			fnType = $target.attr('fn-type');

		if (fnType == 'edit') {
			_factory.openEditingOperator();
		} else if (fnType == 'del') {
			console.log('del');
			_factory.openBlockTip($target, 'del-tip');
		} else if (fnType == 'accept') {
			console.log('accept');
			_factory.openBlockTip($target, 'accept-tip');
		} else if (fnType == 'reject') {
			console.log('reject');
			_factory.openBlockTip($target, 'reject-tip');
		}
	};

	_factory.addClass = function ($scope, row) {
		return 'd-' + this.field
	};

	$('body').on('click', function (e) {
		var $target = $(e.target);
		var fnTypes = ['del', 'accept', 'reject'],
			showBlockTip = (fnTypes.indexOf($target.attr('fn-type')) !== -1) || $target.hasClass('block-tip');
		showBlockTip = showBlockTip || $target.parents('.block-tip').length > 0;
		if (!showBlockTip) {
			$timeout(function () {
				$('.block-tip').removeClass('show');
			}, 100);
		}
	});

	return _factory;
});
