/**
 * Created by Tinny Wang on 2017/9/17.
 */

app.factory('EditFormFactory', function () {
	var _factory = {};
	
	var radioParam = [
		{
			value: 0,
			label: '公開'
		},
		{
			value: 1,
			label: '保護'
		},
		{
			value: -1,
			label: '不公開'
		}
	];


	// ---------- setting ---------- //
	
	_factory.setting = {};

	_factory.setting.manager = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '管理者編輯',
					confirmName: '儲存修改'
				},
				form: [{
					name: 'name',
					label: '管理者',
					text: row.name
				}, {
					name: 'auth',
					type: 'select',
					label: '分類',
					options: params.options,
					placeholder: '未分類'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增管理者',
					confirmName: '新增'
				},
				form: [{
					name: 'member',
					type: 'search',
					label: '管理者',
					placeholder: '輸入名稱或Email',
					url: '/api/member/autocomplete/',
					'url-data-field': 'data',
					'title-field': 'name',
					'description-field': 'email',
					'image-field': 'avatar',
					'text-no-results': '請確認會員名稱或email是否正確',
					'search-fields': 'name,email'
				}, 
				// 	{
				// 	name: 'auth',
				// 	type: 'select',
				// 	label: '分類',
				// 	options: params.options,
				// 	placeholder: '未分類'
				// }
				]
			};
		}
	};

	// ---------- content ---------- //

	_factory.content = {
		category: {},
		member: {},
		project: {},
		course: {},
		author: {},
		advertisement: {}
	};
	
	_factory.content.category.all = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '修改分類',
					confirmName: '保存修改'
				},
				form: [{
					name: 'categoryName',
					type: 'input',
					label: '分類名稱',
					placeholder: '美術',
					maxlength: 10
				}, {
					name: 'isPublic',
					type: 'checkbox',
					label: '是否公開'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增分類',
					confirmName: '新增'
				},
				form: [{
					name: 'categoryName',
					type: 'input',
					label: '分類名稱',
					placeholder: '美術',
					maxlength: 10
				}, {
					name: 'isPublic',
					type: 'checkbox',
					label: '是否公開'
				}]
			};
		}
	};
	
	_factory.content.member.all = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '會員編輯',
					confirmName: '儲存修改'
				},
				form: [{
					name: 'member',
					label: '會員名稱',
					text: row.name
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '未分類'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增會員',
					confirmName: '新增會員'
				},
				form: [{
					name: 'member',
					type: 'search',
					label: '搜尋會員',
					placeholder: '輸入名稱或Email',
					url: '/api/member/autocomplete/',
					'url-data-field': 'data',
					'title-field': 'name',
					'description-field': 'email',
					'image-field': 'avatar',
					'text-no-results': '請確認會員名稱或email是否正確',
					'search-fields': 'name,email'
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '未分類'
				}]
			};
		}
	};
	_factory.content.member.audit = {
		getEditMode: function (params, row) {},
		getNewMode: function (params) {}
	};
	
	_factory.content.project.all = {
		getEditMode: function (params, row) {
			
			var originalCategories = row.originalCategories || [],
				categoryNote = [];
			originalCategories.forEach(function (category) {
				categoryNote.push(category.name);
			});
			if(categoryNote.length > 0){
				categoryNote = '投稿者建議分類：' + categoryNote.join('、');
			} else {
				categoryNote = undefined;
			}
			
			return {
				modal: {
					title: '修改專案',
					confirmName: '送出修改'
				},
				form: [{
					name: 'project',
					type: 'text',
					label: '專案',
					text: row.project.name
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '點擊選擇分類',
					note: categoryNote
				}, {
					name: 'auth',
					type: 'radio',
					label: '授權設定',
					radios: radioParam
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增專案',
					confirmName: '新增專案'
				},
				form: [{
					name: 'project',
					type: 'search',
					label: '搜尋專案',
					placeholder: '輸入專案名稱',
					url: '/api/project/autocomplete/',
					// 'url-data-field': 'data',
					'title-field': 'name',
					'text-no-results': '請確認專案名稱是否正確',
					'search-fields': 'name'
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '點擊選擇分類'
				}, {
					name: 'auth',
					type: 'radio',
					label: '授權設定',
					radios: radioParam
				}]
			};
		}
	};
	_factory.content.project.recommend = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '修改專案',
					confirmName: '送出修改'
				},
				form: [{
					name: 'project',
					type: 'text',
					label: '專案',
					text: row.project.name
				}, {
					name: 'categories',
					type: 'select',
					label: '分類',
					options: params.options,
					placeholder: '點擊選擇分類'
				}, {
					name: 'isPublic',
					type: 'checkbox',
					label: '是否公開'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增推薦',
					confirmName: '新增推薦'
				},
				form: [{
					name: 'project',
					type: 'select',
					label: '挑選專案',
					options: params.options,
					placeholder: '點擊選擇專案'
				}]
			};
		}
	};
	_factory.content.project.audit = {
		getEditMode: function (params, row) {
			
		},
		getNewMode: function (params) {
			
		}
	};

	_factory.content.course.all = {
		getEditMode: function (params, row) {
			
			var originalCategories = row.originalCategories || [],
				categoryNote = [];
			originalCategories.forEach(function (category) {
				categoryNote.push(category.name);
			});
			if(categoryNote.length > 0){
				categoryNote = '投稿者建議分類：' + categoryNote.join('、');
			} else {
				categoryNote = undefined;
			}
			
			return {
				modal: {
					title: '修改課程',
					confirmName: '送出修改'
				},
				form: [{
					name: 'course',
					type: 'text',
					label: '課程',
					text: row.course.name
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '點擊選擇分類',
					note: categoryNote
				}, {
					name: 'auth',
					type: 'radio',
					label: '授權設定',
					radios: radioParam
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增課程',
					confirmName: '新增課程'
				},
				form: [{
					name: 'course',
					type: 'search',
					label: '搜尋課程',
					placeholder: '輸入課程名稱',
					url: '/api/course/autocomplete/',
					'url-data-field': 'data',
					'title-field': 'name',
					// 'description-field': 'email',
					// 'image-field': 'avatar',
					'text-no-results': '請確認課程名稱是否正確',
					'search-fields': 'name'
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '點擊選擇分類'
				}, {
					name: 'auth',
					type: 'radio',
					label: '授權設定',
					radios: radioParam
				}]
			};
		}
	};
	_factory.content.course.recommend = {
		getEditMode: function (params, row) {
			
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增推薦',
					confirmName: '新增推薦'
				},
				form: [{
					name: 'course',
					type: 'select',
					label: '挑選課程',
					options: params.options,
					placeholder: '點擊選擇課程'
				}]
			};
		}
	};
	_factory.content.course.audit = {
		getEditMode: function (params, row) {},
		getNewMode: function (params) {}
	};

	_factory.content.author.all = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '作者編輯',
					confirmName: '儲存修改'
				},
				form: [{
					name: 'member',
					label: '作者名稱',
					text: row.name
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '未分類'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增作者',
					confirmName: '新增作者'
				},
				form: [{
					name: 'member',
					type: 'search',
					label: '搜尋會員',
					placeholder: '輸入名稱或Email',
					url: '/api/member/autocomplete/',
					'url-data-field': 'data',
					'title-field': 'name',
					'description-field': 'email',
					'image-field': 'avatar',
					'text-no-results': '請確認會員名稱或email是否正確',
					'search-fields': 'name,email'
				}, {
					name: 'categories',
					type: 'select-multi',
					label: '分類',
					options: params.categoryOptions,
					placeholder: '未分類'
				}]
			};
		}
	};
	_factory.content.author.audit = {
		getEditMode: function (params, row) {},
		getNewMode: function (params) {}
	};

	_factory.content.advertisement.home = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '修改廣告',
					confirmName: '儲存修改'
				},
				form: [{
					name: 'name',
					type: 'input',
					label: '廣告名稱'
				}, {
					name: 'linkUrl',
					type: 'input',
					label: '開啟連結',
					placeholder: 'https://'
				}, {
					name: 'image',
					type: 'image-upload',
					label: '上傳圖片',
					note: '只能上傳 jpg/png 文件 (350*920px)，且不超過 2MB',
					width: '276px',
					height: '105px'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增廣告',
					confirmName: '新增'
				},
				form: [{
					name: 'name',
					type: 'input',
					label: '廣告名稱'
				}, {
					name: 'linkUrl',
					type: 'input',
					label: '開啟連結',
					placeholder: 'https://'
				}, {
					name: 'image',
					type: 'image-upload',
					label: '上傳圖片',
					note: '只能上傳 jpg/png 文件 (350*920px)，且不超過 2MB',
					width: '276px',
					height: '105px'
				}]
			};
		}
	};
	_factory.content.advertisement.side = {
		getEditMode: function (params, row) {
			return {
				modal: {
					title: '修改廣告',
					confirmName: '儲存修改'
				},
				form: [{
					name: 'name',
					type: 'input',
					label: '廣告名稱'
				}, {
					name: 'linkUrl',
					type: 'input',
					label: '開啟連結',
					placeholder: 'https://'
				}, {
					name: 'image',
					type: 'image-upload',
					label: '上傳圖片',
					note: '只能上傳 jpg/png 文件，且不超過 2MB',
					width: '160px',
					height: '120px'
				}]
			};
		},
		getNewMode: function (params) {
			return {
				modal: {
					title: '新增廣告',
					confirmName: '新增'
				},
				form: [{
					name: 'name',
					type: 'input',
					label: '廣告名稱'
				}, {
					name: 'linkUrl',
					type: 'input',
					label: '開啟連結',
					placeholder: 'https://'
				}, {
					name: 'image',
					type: 'image-upload',
					label: '上傳圖片',
					note: '只能上傳 jpg/png 文件，且不超過 2MB',
					width: '160px',
					height: '120px'
				}]
			};
		}
	};

	// ---------- analytics ---------- //

	_factory.analytics = {};
	
	return _factory;
});
